import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

// Images
import Image from "../components/image";

// Styles
import "../styles/style.css";

const Intro = () => {
  return (
    <div id="home" className="intro-block">
      <div className="container">
        <div className="row">
          <div className="headshot-col col">
            <div className="headshot">
              <Image name="headshot" />
              <div className="svg-content">
                <svg
                  className="cross-1"
                  height="329pt"
                  viewBox="0 0 329.26933 329"
                  width="329pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>
                <svg
                  className="cross-2"
                  height="329pt"
                  viewBox="0 0 329.26933 329"
                  width="329pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>
                <svg
                  className="cross-3"
                  height="329pt"
                  viewBox="0 0 329.26933 329"
                  width="329pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>
              </div>
            </div>
          </div>
          <div className="intro-desc col">
            <h1>Hi, I'm Nelson</h1>
            <h2>Full Stack Engineer, based in Australia</h2>
            <p className="intro-para">
              I'm an experienced software developer determined to create amazing
              applications, tools and solutions that help change the world.
            </p>
            <div className="contact-btns intro-button">
              <button className="contact-btn">
                <a href="https://github.com/NelsonG-C">
                  <FaGithub />
                </a>
              </button>
              <button className="contact-btn">
                <a href="https://www.linkedin.com/in/nelsongc/">
                  <FaLinkedin />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
